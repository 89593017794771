
export default {
  props: {
    classes: {
      type: String,
      default: ''
    }
  },
  computed: {
    itemsWithHome() {
      const routeItems = this.$route.path.split('/').filter((item) => item)
      const nonRouteItems = ['lists']
      const items = [
        {
          label: 'Home',
          url:'/',
          link: {
            name: '/'
          }
        }
      ]

      // Build breadcrumb items with links
      routeItems.forEach((routeItem, index) => {

        const fixedItem = routeItem.replace(/-/g,' ')
        
        if (index === 0) {
          items.push({
            label: fixedItem,
            url: this.$route.path.split('/').slice(0,index+2).join('/')+'/'
          })
        } else {
          const item = {
            label: fixedItem
          }

          if (!nonRouteItems.includes(routeItem)) {
            item.url = this.$route.path.split('/').slice(0,index+2).join('/')+'/'
          }
          items.push(item)
        }
      })

      return items
    }
  }
}
