
  import { mapGetters, mapActions } from 'vuex'
  import productData from '@/data/products.json'
  import optionsData from '@/data/options.json';
  export default {
      props: {
        page: {
          type: Object,
          default() {
              return {}
          }
        },
      },
      data(){
        return{
          // isVisible:false
          pid: null,
          vid: null,
          options: optionsData
        }
      },
      computed: {
        ...mapGetters({
            regionClicked : 'cart/getRegionClicked',
            pageRegion : 'pages/getPageRegion'
        }),
        product(){
          const pid =
          this.$page?.productId ||
          this.$store.state.pages.page.productId ||
          this.$props.product_id
  
          let getProduct = {}
  
          getProduct = productData.find((o) => {
            return o.id.toString() === pid
          })
  
          getProduct.classroomId = this.$props.classroom_id || null
          return getProduct
  
        },
        virtual_product(){
          const vid =
          this.$store.state.pages.page.virtualProductId ||
          this.$page?.virtualProductId ||
          null
  
          // eslint-disable-next-line prefer-const
          let getVProduct = {}
  
          if (vid) {
            getVProduct = productData.find((o) => {
              return o.id.toString() === vid
            })
            getVProduct.classroomId = this.$props.classroom_id || null
          }
  
          return getVProduct
        },
        vpriceData(){
          let getVPriceData = {}
          if (Object.keys(this.virtual_product).length > 0) {
            getVPriceData = this.$options.filters.priceData(
              this.virtual_product
            )
          }
          // this.virtual_product.onSale = getVPriceData.saleType !== 'none'
          return getVPriceData
        },
        priceData(){
          const getPriceData = this.$options.filters.priceData(
              this.product
          )
          // this.product.onSale = getPriceData.saleType !== 'none'
          return getPriceData
        },
        hasBoth(){
          const pid =  this.$store.state.pages.page.productId
          const vid =  this.$store.state.pages.page.virtualProductId || null
  
          if(pid && vid && pid !== vid){
            return true
          } 
          
          return false
        },
        headerImage() {
            const size =  this.page.headerSize || 'cover'
            const position = this.page.headerPosition || '50% 50%'
            const imgUrl = this.$img(this.$options.filters.cdn(this.page.thumbnail), { width: '1400px',format:'webp',quality:70 })
            return {
              backgroundImage: `url('${imgUrl}')`,
              backgroundSize: size,
              backgroundPosition: position
            }
          },
      },
      methods: {
          ...mapActions({
              addToCart:'cart/addToCartServer'
          })
      }
  }
  